<template lang="pug">
b-card
  validation-observer(ref="form", tag="form")
    b-form
      b-row
        // Name
        b-col(md="6")
          b-form-group(
            :label="$t('label.Name')",
            :label-for="$t('label.Name')",
            description=""
          )
            validation-provider(:name="$t('label.Name')", rules="required", v-slot="{ errors }")
              b-form-input(
                id="name",
                v-model="data.name",
                trim,
                :placeholder="$t('placeholder.name')"
              )
              small(class="text-danger") {{ errors[0] }}

        // Upload document
        b-col(md="6", v-if="!route.params.id")
          b-form-group(
            :label="$t('label.document')",
            :label-for="$t('label.document')",
            description=""
          )
            b-button.mr-1(
              type="button",
              variant="info",
              @click="activateFileSelector"
            )
              | {{ $t('message.upload') }}
            input(
              type="file",
              ref="fileSelector"
              style="display: none",
              :accept="inputFileTypes",
              @change="changeFileSelected"
            )
            small(v-if="documentFile.name")
              | {{ documentFile.name }}
            small(v-else, class="text-danger")
              | {{ $t('message.upload_document_required') }}

      // Button
      b-row
        b-col(
          cols="12"
          class="text-right"
        )
          b-button(
            variant="primary",
            :disabled="!route.params.id && !documentFile.name || isSubmitting",
            @click="validateAndSubmit"
          )
            | {{ $t('message.submit') }}
      
      // Loading message
      b-row(v-if="isSubmitting && !route.params.id")
        b-col(
          cols="12"
          class="text-right"
        )
          div(class="mt-1")
            | {{ $t('message.uploading_document') }}

</template>

<script>
// eslint-disable-next-line import/extensions
import { ref, toRefs, onMounted } from '@vue/composition-api/dist/vue-composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import vSelect from 'vue-select'
import { useRouter } from '@core/utils/utils'
import { inputFileTypes } from '@/constants'

export default {
  name: 'Form',

  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },

  props: ['data', 'isSubmitting'],

  setup(props, { emit }) {
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { route } = useRouter()
    const form = ref(null)
    const fileSelector = ref(null)
    const documentFile = ref({})

    const activateFileSelector = () => {
      fileSelector.value.click()
    }

    const changeFileSelected = (e) => {
      documentFile.value = e.target.files[0]
    }

    const validateDocument = () => new Promise((resolve, reject) => {
      form.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = () => {
      validateDocument()
      .then(() => emit('submit', toRefs(props.data), documentFile.value))
      .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    return {
      required,
      route,
      form,
      fileSelector,
      documentFile,
      activateFileSelector,
      changeFileSelected,
      validateAndSubmit,
      inputFileTypes,
    }
  },
}
</script>

<style scoped>

</style>
